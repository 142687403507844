<template>
  <div>
    <div class="nav">
      <div
        v-for="(item, index) in nav"
        :key="index"
        class="navItem"
        @click="navClick(index)"
      >
        <div class="navItem-name">{{ item.name }}</div>
        <div class="nav-underline" v-if="index == navIndex"></div>
      </div>
    </div>

    <!-- 客户 -->
    <div v-if="navIndex == 0">
      <div
        @click="router(item, '/CustomerDynamics')"
        class="customerUser rel"
        v-for="(item, index) in user"
        :key="index"
      >
        <div class="customerUser-top flex flex-center">
          <img :src="item.avatar" alt="" />
          <div class="customerUser-top_user">
            <div>{{ item.name }}</div>
            <div class="flex flex-center" style="flex-wrap: wrap;">
              <div
                class="customerUser-top_label"
                v-for="(val, idx) in item.tag_arr"
                :key="idx"
              >
                {{ val }}
              </div>
            </div>
          </div>
        </div>
        <div class="customerUser-text">专属客服 {{ item.employee_id }}</div>
        <div class="customerUser-text">添加时间 {{ item.createtime }}</div>
        <img
          src="../assets/imgs/chat.png"
          @click.stop="chat(item)"
          alt=""
          class="customerUser-icon abs"
        />
      </div>
    </div>

    <!-- 客户群 -->
    <div v-if="navIndex == 1">
      <div
        class="group flex column flex-center"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="groupTop flex flex-center">
          <div>{{ item.name }}</div>
          <!-- <div class="flex flex-center">
            <div class="groupTop-text">详情</div>
            <img class="groupTop-icon" src="../assets/imgs/arrow.png" alt="" />
          </div> -->
        </div>

        <div class="groupCenter flex flex-center">
          <div>
            <div class="groupCenter-name">群主: {{ item.owner }}</div>
            <div class="groupCenter-time">创建时间: {{ item.create_time }}</div>
          </div>
          <div class="flex flex-center groupCenter-leftBorder">
            <div></div>
            <div class="flex flex-center" @click="goChat(item)">
              <img
                class="groupCenter-icon"
                src="../assets/imgs/chat.png"
                alt=""
              />
              <div>进入群聊</div>
            </div>
          </div>
        </div>
        <div class="groupCenterBottom">
          <div class="groupCenterBottom-title">群信息</div>
          <div class="groupCenterBottom-item flex">
            <div>群成员{{ item.member_count }}</div>
            <!-- <div class="groupCenterBottom-item_num">客户190</div> -->
            <!-- <div class="groupCenterBottom-item_right">员工9</div> -->
          </div>
          <!-- <div class="groupCenterBottom-label flex wrap">
            <div class="groupCenterBottom-label_item">健身</div>
          </div> -->
          <div v-if="item.notice"  class="groupCenterBottom-label">
            群公告：{{ item.notice}}
          </div>
        </div>
      </div>
<!-- 
      <div class="dybox flex column flex-center">
        <div class="dybox-nav flex flex-center">
          <div
            class="dybox-nav_item flex-item_center"
            :class="listIndex == index ? 'active-nav_item' : ''"
            v-for="(item, index) in list"
            @click="listClick(index)"
            :key="index"
          >
            {{ item }}
          </div>
        </div>

        <div class="tomer" v-if="listIndex == 0">
          <div class="groupCenterBottom-title">2022.07.30</div>
          <div class="groupCenterBottom-item flex">
            <div>群客户数: 总数 99</div>
            <div class="groupCenterBottom-item_num">新增 2</div>
            <div class="groupCenterBottom-item_right">退群 1</div>
          </div>
          <div class="tomerTop flex flex-center">
            <div class="flex flex-center">
              <div class="tomerTop-name">小双同学</div>
              <div class="tomerTop-text">访问了素材</div>
            </div>
            <div class="tomerTop-time">22:00</div>
          </div>
          <div class="tomerContent flex flex-center">
            <img src="../assets/imgs/activity.png" alt="" />
            <div class="tomerContent-item">
              国庆活动攻略，国庆活动攻略，国庆活动攻略
            </div>
          </div>
        </div>

        <div v-if="listIndex == 1">
          <div class="number flex flex-center column">
            <div class="flex flex-center number-num">
              <div
                class="number-item flex-item_center"
                v-for="(item, index) in [0, 1, 2, 3, 4, 5, 6]"
                :key="index"
              >
                12
              </div>
            </div>
            <img class="number-icon" src="../assets/imgs/down.png" alt="" />
          </div>

          <div
            class="numList"
            v-for="(item, index) in [0, 1, 2, 3]"
            :key="index"
          >
            <div class="flex flex-center">
              <div class="numList-L flex-item_center">SDP</div>
              <div class="numList-N">晚间群活跃</div>
            </div>
            <div class="flex flex-center numList-o">
              <div class="numList-time">2022.08.20 22:00</div>
              <div class="numList-status_success">未完成</div>
              <div class="numList-status_error">未完成</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="padding-b"></div>
    <tabbar :current="1" :isShow="6"></tabbar>
  </div>
</template>

<script>
// import wx from "weixin-js-sdk";
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import Tabbar from "@/components/tabbar.vue";

export default {
  components: { Tabbar },

  data() {
    return {
      nav: [{ name: "客户" }, { name: "客户群" }],
      navIndex: 0,
      list: ["群客户动态", "运营任务"],
      listIndex: 0,
      page: 1,
      user: [],
      items: [],
    };
  },
  async mounted() {
    let that = this;
    this.getUser();

    let res = await request._getConfig({
      url: location.href.split("#")[0],
    });
    console.log("获取配置", res);
    if (_.toInteger(res.code) == 1) {
      console.log("进来额");
      let result = res.data;
      that.$wx.config({
        beta: true,
        debug: false, // 关闭调试模式
        appId: result.corpid,
        timestamp: result.timestamp,
        nonceStr: result.nonceStr,
        signature: result.signature,
        jsApiList: ["openExistedChatWithMsg", "openEnterpriseChat"],
      });
      that.$wx.ready(function() {
        that.$wx.agentConfig({
          corpid: result.corpid,
          agentid: result.agentid,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature2,
          jsApiList: ["openEnterpriseChat", "openExistedChatWithMsg"],
          success: function(res) {
            console.log("res", res);
            // 回调
          },
          fail: function(res) {
            console.log("that.$wx.agentConfig err", res);
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
        that.$wx.error(function(res) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      });
    }
  },
  methods: {
    // 进去群聊
    goChat(item) {
      // console.log("item", item);
      // alert("item.id", item.chat_id);
      this.$wx.invoke(
        "openExistedChatWithMsg",
        {
          chatId: item.chat_id,
        },
        function(res) {
          console.log("res", res);
          if (res.err_msg == "openExistedChatWithMsg:ok") {
          }
        }
      );
    },
    // 查看客户列表
    async getUser() {
      let res = await request._userList({
        userid: localStorage.getItem("userid"),
        page: this.page,
      });
      console.log("客户 res", res);
      if (_.toInteger(res.code) === 1) {
        this.user = res.data.data;
      }
    },
    // 查看客户群 列表
    async getGrup() {
      let res = await request._getAllGroup({
        userid: localStorage.getItem("userid"),
      });
      console.log("客户 res", res);
      if (_.toInteger(res.code) === 1) {
        this.items = res.data;
      }
    },
    router(item, path) {
      this.$router.push({
        path,
        query: {
          id: item.id,
          external_userid: item.external_userid,
        },
      });
    },
    chat(item) {
      this.$wx.openEnterpriseChat({
        userIds: item.employee_id,
        externalUserIds: item.external_userid,
        groupName: "会话",
        chatId: "",
        success: function(res) {
          console.log("Res", res);
          var chatId = res.chatId; //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
          // 回调
        },
        fail: function(res) {
          console.log("res", res);
          if (res.errMsg.indexOf("function not exist") > -1) {
            alert("版本过低请升级");
          }
        },
      });
    },
    navClick(index) {
      this.navIndex = index;
      if (index == 0) {
        this.getUser();
      } else {
        this.getGrup();
      }
    },
    listClick(index) {
      this.listIndex = index;
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  padding: 0 111px 0 127px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7d8393;
  background: #fff;
}

.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100px;
}

.navItem-name {
  margin-top: 34px;
}
.nav-underline {
  width: 91px;
  height: 6px;
  background: #0558f4;
  border-radius: 3px 3px 3px 3px;
  bottom: 0;
  position: absolute;
}

.customerUser {
  width: 690px;
  padding: 0 0 25px 0;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  margin: 16px auto 0;
}

.customerUser-top {
  padding: 30px 0 35px 30px;
}

.customerUser-top img {
  width: 99px;
  height: 99px;
  border-radius: 50%;
  margin-right: 20px;
}

.customerUser-top_user {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  padding: 0 0 25px 0;
}

.customerUser-top_label {
  padding: 3px 24px;
  background: #edf3ff;
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
  margin: 18px 10px 0 0;
  border-radius: 4px 4px 4px 4px;
}

.customerUser-text {
  font-size: 26px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  margin: 0 0 8px 30px;
}

.customerUser-icon {
  width: 72px;
  height: 72px;
  top: 15px;
  right: 30px;
}

.group {
  width: 690px;
  height: auto;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  margin: 23px auto 0;
}

.groupTop {
  width: 630px;
  border-bottom: 1px solid #d6e5ee;
  padding: 30px 0 27px 0;
  justify-content: space-between;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.groupTop-text {
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.groupTop-icon {
  width: 12px;
  height: 20px;
  margin-left: 7px;
}

.groupCenter {
  width: 630px;
  padding: 28px 0;
  border-bottom: 1px solid #d6e5ee;
  justify-content: space-between;
}

.groupCenter-name {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  margin-bottom: 11px;
}

.groupCenter-time {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7d8393;
}

.groupCenter-icon {
  width: 56px;
  height: 56px;
  margin-right: 16px;
}

.groupCenter-leftBorder {
  width: 232px;
  border-left: 1px solid #d6e5ee;
  justify-content: space-between;
}

.groupCenterBottom {
  padding: 29px 0 35px 0;
  width: 630px;
}

.groupCenterBottom-title {
  font-size: 30px;
  width: 630px;
  padding-bottom: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.groupCenterBottom-item {
  font-size: 24px;
  width: 630px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
  margin-bottom: 25px;
}

.groupCenterBottom-item div {
  padding-right: 29px;
}

.groupCenterBottom-item_num {
  padding-left: 29px;
  border-right: 1px solid #d6e5ee;
  border-left: 1px solid #d6e5ee;
}

.groupCenterBottom-item_right {
  padding-left: 29px;
}

.groupCenterBottom-label_item {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
  background: #edf3ff;
  padding: 5px 39px;
  margin-right: 16px;
}

.dybox {
  margin: 16px auto;
  width: 690px;
  padding: 24px 0;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
}

.dybox-nav {
  width: 630px;
  height: 98px;
  background: #f4f5f7;
  border-radius: 16px 16px 16px 16px;
  padding: 0 54px;
  justify-content: space-between;
}

.dybox-nav_item {
  width: 199px;
  height: 64px;
}

.active-nav_item {
  background: #ffffff;
  box-shadow: 0px 4px 8px 1px #eaebee;
  border-radius: 8px 8px 8px 8px;
  color: #0558f4;
}

.tomer {
  padding: 36px 0;
  width: 630px;
  border-bottom: 1px solid #d6e5ee;
}

.tomerTop {
  justify-content: space-between;
  width: 630px;
}

.tomerTop-name {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.tomerTop-text {
  font-size: 28px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
  margin-left: 15px;
}

.tomerTop-time {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
}

.tomerContent {
  padding: 17px 0 17px 16px;
  background: #f4f5f7;
  width: 630px;
  margin-top: 13px;

  border-radius: 16px 16px 16px 16px;
}

.tomerContent img {
  width: 52px;
  height: 52px;
  margin-right: 12px;
}

.tomerContent-item {
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.number {
  width: 630px;
  padding: 46px 0 29px 0;
  justify-content: space-between;
  font-size: 28px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #0558f4;
  border-bottom: 1px solid #d6e5ee;
}

.number-num {
  width: 630px;
  justify-content: space-between;
  margin-bottom: 19px;
}
.number-item {
  width: 54px;
  height: 54px;
  background: rgba(5, 88, 244, 0.11);
  opacity: 1;
  border: 2px solid #0558f4;
  border-radius: 50%;
}

.numList {
  padding: 42px 0 37px 0;
  width: 630px;
  border-bottom: 1px solid #d6e5ee;
}

.numList-L {
  width: 79px;
  height: 36px;
  background: #0558f4;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.numList-N {
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  margin-left: 14px;
}

.numList-o {
  justify-content: space-between;
  margin-top: 40px;
}

.numList-time {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
}

.numList-status_success {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.numList-status_error {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ed184f;
}

.number-icon {
  width: 22px;
  height: 22px;
}

.padding-b {
  height: 160px;
}
</style>
